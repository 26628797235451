import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// const ls = new SecureLS({
//   isCompression: false
// });

export default createStore({
  plugins: [
    createPersistedState({
      key: "pladico-app",
      // storage: {
      //   // getItem: (key) => ls.get(key),
      //   // setItem: (key, value) => ls.set(key, value),
      //   // removeItem: (key) => ls.remove(key)
      // }
    }),
  ],
  state: {
    locale: "hr",
  },
  getters: {
    getLocale(state: any): string {
      return state.locale;
    },
  },
  mutations: {
    setLocale(state: any, data: string): void {
      state.locale = data;
    },
  },
  actions: {
    setLocale({ commit }, data: string): void {
      commit("setLocale", data);
    },
  },
  modules: {},
});
